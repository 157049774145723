import Image from "next/image";
import { useRouter } from "next/router";

interface Provider {
  id: string;
  name: string;
}
function SocialLogin({
  providers,
  callbackHandler,
  from,
}: {
  providers: Record<string, Provider>;
  callbackHandler: any;
  from?: string;
}) {
  const router = useRouter();

  return (
    <div className="w-[133px] h-[60px] justify-start items-start gap-[13px] inline-flex">
      {Object?.values(providers).map(
        (provider: Provider, i) =>
          provider.name !== "Credentials" && (
            <div
              key={i}
              onClick={() => {
                callbackHandler({ provider, router, from });
              }}
              className={` cursor-pointer w-[60px] h-[60px] relative flex items-center gap-3 justify-center text-sm font-semibold rounded-[60px]`}
            >
              <Image
                src={`/images/icons/login/${provider.name.toLowerCase()}.svg`}
                width={60}
                height={60}
                alt=""
              />
            </div>
          )
      )}
    </div>
  );
}
export default SocialLogin;
