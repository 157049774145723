import Image from "next/image";
import { useState } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";
import useLogin, { callbackHandler, handleLogin } from "utils/join/login";
import SocialLogin from "components/templates/common/Join/SocialLogin";
import Button from "components/common/buttons/buttons";
import PasswordModal from "components/modals/PasswordModal";
import LoginSubButtons from "components/templates/common/Join/LoginSubButtons";

interface Provider {
  id: string;
  name: string;
}

interface Props {
  providers: Record<string, Provider>;
}

const Login = ({ providers }: Props) => {
  const { data: session, status } = useSession();
  const router = useRouter();

  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailfocused, setEmailforcused] = useState(false);
  const [passwordfocused, setPasswordfocused] = useState(false);
  const { t } = useTranslation("login");

  const handleBlur = (select: string) => {
    if (select === "email") {
      setTimeout(() => {
        setEmailforcused(false);
      }, 100);
    } else {
      setTimeout(() => {
        setPasswordfocused(false);
      }, 100);
    }
  };

  const clearInput = (select: string) => {
    if (select === "email") {
      setEmail("");
    } else {
      setPassword("");
    }
  };
  const { from }: any = router.query;

  useLogin({ router, session, status, t, setMessage });

  return (
    <main className="min-w-[360px] py-[100px]  text-sm">
      <div className="w-full  pb-[140px] flex-col justify-start items-center gap-[30px] inline-flex">
        <div
          className={`${TYPO.TYPO3_B} h-[57.51px] justify-start items-start gap-3 inline-flex`}
        >
          <Image
            src={"/images/header/rwlogo.svg"}
            alt="logo"
            height={57}
            width={68}
          />
          <Image
            src={"/images/header/riskWeather.svg"}
            alt="logo"
            height={57}
            width={150}
          />
        </div>
        <div className="w-[327px] h-[428px] flex-col justify-start items-center gap-10 inline-flex">
          <div className="h-[102px] flex-col justify-start items-center gap-4 inline-flex">
            <p className={`${TYPO.TYPO6_MD} text-gray70`}>
              {t("loginExplain")}
            </p>
            <SocialLogin
              providers={providers}
              callbackHandler={callbackHandler}
              from={from}
            />
          </div>
          <div className="w-full flex items-center">
            <hr className="flex-grow border-t border-gray-300" />
            <span className={`${TYPO.TYPO8_MD} text-gray30 px-[16px]`}>
              {t("or")}
            </span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>

          <div className="h-[226px] flex-col justify-start items-center gap-3 inline-flex w-full">
            <div className="h-[190px] flex-col justify-start items-start gap-6 inline-flex w-full">
              {/** 이메일 비밀번호 인풋 */}
              <div className=" h-[118px] flex-col justify-start items-start gap-3.5 inline-flex w-full relative">
                <div className="relative w-full">
                  <input
                    value={email}
                    onFocus={() => setEmailforcused(true)}
                    onBlur={() => handleBlur("email")}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("email")}
                    className={`box-input bg-white w-full h-[52px]"   px-3 py-2 ${TYPO.TYPO5_MD}  text-gray90 border border-gray5 rounded-xl`}
                  />
                  {emailfocused && (
                    <div
                      className=" z-30 x-btn absolute top-[50%] translate-y-[-50%] cursor-pointer right-[10px]"
                      onClick={() => clearInput("email")}
                    >
                      <Image
                        alt="x"
                        src={"/images/icons/x.svg"}
                        height={20}
                        width={20}
                      />
                    </div>
                  )}
                </div>
                <div className="relative w-full">
                  <input
                    type={password ? "password" : "text"}
                    value={password}
                    onFocus={() => setPasswordfocused(true)}
                    onBlur={() => handleBlur("password")}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("password")}
                    className={` box-input bg-white w-full h-[52px]"   px-3 py-2 ${TYPO.TYPO5_MD}  text-gray90 border border-gray5 rounded-xl`}
                  />
                  {passwordfocused && (
                    <div
                      className=" z-30 x-btn absolute top-[50%] translate-y-[-50%] cursor-pointer right-[10px]"
                      onClick={() => clearInput("password")}
                    >
                      <Image
                        alt="x"
                        src={"/images/icons/x.svg"}
                        height={20}
                        width={20}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/** 로그인 버튼 */}
              <Button
                clickHandler={() =>
                  handleLogin({ router, email, password, setMessage, from })
                }
                text={t("loginButton")}
                type={"primary"}
                size={"lg"}
                width="w-full"
              />
            </div>
            <p className={`${TYPO.TYPO7_MD} text-red40`}>{message}</p>
            {/** 비밀번호 재설정 및 회원가입 */}
            <LoginSubButtons setShowModal={setShowModal} t={t} />
          </div>
        </div>

        {showModal && (
          <PasswordModal
            isOpen={showModal}
            closeModal={() => setShowModal(false)}
          />
        )}
      </div>
    </main>
  );
};

export default Login;
