import { ReactNode } from "react";

interface ModalProps {
  children: ReactNode;
  modalRef: React.LegacyRef<HTMLDivElement> | undefined;
  containerClass?: string;
}

const CenterModal = ({ children, modalRef, containerClass }: ModalProps) => {
  const modalClasses = `min-w-[300px] absolute flex top-1/2 left-1/2 translate-x-half translate-y-half bg-white py-6 px-5 rounded-20 flex-col ${
    containerClass ? containerClass : ""
  }  `;
  return (
    <div
      className={`z-[100] fixed top-0 left-0 w-full  h-full flex items-center justify-center bg-black bg-opacity-50`}
    >
      <main ref={modalRef} className={modalClasses}>
        {children}
      </main>
    </div>
  );
};

export default CenterModal;
