import React from "react";
import { useRouter } from "next/router";
import { useRef, useState } from "react";

import useModalClose from "utils/useModalClose";
import { useSignUpState, State, Action } from "contexts/SignUpContext";
import { toast } from "react-toastify";
import { TYPO } from "styles/Typo";
import Button from "components/common/buttons/buttons";
import { useTranslation } from "next-i18next";
import useModalClickOutside from "utils/useModalClickOutside";
import CenterModal from "components/common/Modal/CenterModal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const PasswordModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
  const router = useRouter();
  const userEmail = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState(false);
  const [emailValid, setEmailValid] = useState(true);

  //   const modalRef = useModalClose(isOpen, closeModal);
  const modalRef = useModalClickOutside(closeModal); // 모달 참조를 위한 ref 추가

  const [message, setMessage] = useState("");
  let authResponse;
  // const confirmEmail = () => {
  //   if (!email.includes("@") || email.length > 30 || email.length < 5) {
  //     setMessage(
  //       router.locale === "ko"
  //         ? "이메일 형식을 확인해주세요"
  //         : "Check your email format"
  //     );
  //     return;
  //   } else {
  //     setMessage("");
  //     setEmailConfirm(true);
  //   }
  // };

  const sendPasswordChange = async () => {
    if (userEmail.current) {
      const email = userEmail.current?.value as string;
      if (!email.includes("@") || email.length > 30 || email.length < 5) {
        setMessage(
          router.locale === "ko"
            ? "이메일 형식을 확인해주세요"
            : "Check your email format"
        );
        return;
      }

      try {
        const response = await fetch("/api/auth/password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        });

        authResponse = await response.json();

        if (
          response.status == 500 ||
          authResponse.message === "user doesn't exists"
        ) {
          setMessage(
            router.locale === "ko"
              ? "가입된 이메일 주소가 아닙니다."
              : "This email address is not registered."
          );
          setEmailValid(false);

          return;
        }
        closeModal();
        toast(
          router.locale == "ko"
            ? `메일을 보냈어요. (최대 5분 소요)`
            : `Sucessfully sent password reset email`,
          {
            hideProgressBar: true,
            autoClose: 10000,
            type: "success",
            position: "top-center",
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const { t } = useTranslation("login");

  return isOpen ? (
    <CenterModal modalRef={modalRef} containerClass="gap-4">
      <div className="w-full h-[52px] flex-col justify-start items-start inline-flex">
        <h3 className={`${TYPO.TYPO5_SB} text-gray80`}>
          {t("findPasswordTitle")}
        </h3>
        <p className={`${TYPO.TYPO7_MD}  text-gray50`}>
          {t("findPasswordDescription")}
        </p>
      </div>
      <div className="text-center w-full justify-start items-start relative gap-2 flex-col inline-flex">
        <div className="relative w-full">
          <input
            className={`${TYPO.TYPO6_MD} ${
              message.length > 0 && "error-box-input"
            } box-input w-full h-12 px-3 py-2 bg-gray-50 rounded-xl border border-gray5 justify-start items-center gap-2.5 inline-flex`}
            id="email"
            type="text"
            autoComplete="off"
            ref={userEmail}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailConfirm(false);
              setEmailValid(true);
              setMessage("");
            }}
            placeholder={t("email")}
          />
        </div>

        <p className={`${TYPO.TYPO8_MD} text-red40`}>{message}</p>
      </div>
      <Button
        size="lg"
        type="primary"
        disabled={false}
        text={t("findPasswordButton")}
        width={"w-full"}
        clickHandler={() => {
          sendPasswordChange();
        }}
      />
    </CenterModal>
  ) : null;
};

export default PasswordModal;
