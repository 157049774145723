import { useState } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";
import Input from "components/common/inputs";
import useLogin, { callbackHandler, handleLogin } from "utils/join/login";
import SocialLogin from "components/templates/common/Join/SocialLogin";
import Button from "components/common/buttons/buttons";
import PasswordModal from "components/modals/PasswordModal";
import LoginSubButtons from "components/templates/common/Join/LoginSubButtons";

interface Provider {
  id: string;
  name: string;
}

interface Props {
  providers: Record<string, Provider>;
}

const Login = ({ providers }: Props) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation("login");
  const { from }: any = router.query;

  useLogin({ router, session, status, t, setMessage });

  return (
    <main
      className="min-w-[360px] pb-[196px] bg-white  text-sm"
      style={{ minHeight: "calc(100vh - 64px)" }}
    >
      <div className="w-full flex-col justify-start items-center gap-[30px] inline-flex ">
        <div
          className={` flex-col  mt-[24px] w-full px-6 py-4 bg-white justify-start items-start gap-1 inline-flex whitespace-pre-line`}
        >
          <h1 className={`${TYPO.TYPO3_B} text-gray80`}>
            {t("loginMainTitle")}
          </h1>
          <div className={`${TYPO.TYPO7_1_MD} text-gray60`}>
            <span>{t("loginSubTitle")}</span> <br />{" "}
            {router.locale === "ko" ? (
              <>
                <span>모든 판단을</span>{" "}
                <span className="text-blue40">리스크 웨더</span>
                <span>에서</span>
              </>
            ) : (
              <>
                <span>make all your decisions with</span>{" "}
                <span className="text-blue40"> Riskweather.</span>
              </>
            )}
          </div>
        </div>
        <div className=" w-full px-6 flex-col justify-start items-center gap-10 inline-flex">
          <div className="flex-col justify-start items-center gap-4 inline-flex">
            <p className={`${TYPO.TYPO6_MD} text-gray70`}>
              {t("loginExplain")}
            </p>
            <SocialLogin
              providers={providers}
              callbackHandler={callbackHandler}
              from={from}
            />
          </div>
          <div className="w-full flex items-center">
            <hr className="flex-grow border-t border-gray-300" />
            <span className={`${TYPO.TYPO8_MD} text-gray30 px-[16px]`}>
              {t("or")}
            </span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>

          <div className="flex-col justify-start items-center gap-3 inline-flex w-full">
            <div className="h-[190px] flex-col justify-start items-start gap-6 inline-flex w-full">
              {/** 이메일 비밀번호 인풋 */}
              <div className="h-[118px] flex-col justify-start items-start gap-3.5 inline-flex w-full">
                <Input
                  placeholder={t("email")}
                  type="box"
                  size="lg"
                  value={email}
                  setValue={setEmail}
                  width={"w-full"}
                />
                <Input
                  placeholder={t("password")}
                  type="box"
                  size="lg"
                  value={password}
                  setValue={setPassword}
                  width={"w-full"}
                  password={true}
                />
              </div>
              {/** 로그인 버튼 */}
              <Button
                clickHandler={() =>
                  handleLogin({ router, email, password, setMessage, from })
                }
                text={t("loginButton")}
                type={"primary"}
                size={"lg"}
                width="w-full"
              />
            </div>
            <p className={`${TYPO.TYPO7_MD} text-red40`}>{message}</p>
            {/** 비밀번호 재설정 및 회원가입 */}
            <LoginSubButtons setShowModal={setShowModal} t={t} />
          </div>
        </div>

        {showModal && (
          <PasswordModal
            isOpen={showModal}
            closeModal={() => setShowModal(false)}
          />
        )}
      </div>
    </main>
  );
};

export default Login;
