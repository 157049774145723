import { CustomSession } from "interfaces/login";
import { signIn } from "next-auth/react";
import { NextRouter } from "next/router";
import { useEffect } from "react";
import { toast } from "react-toastify";

export interface Provider {
    id: string;
    name: string;
}

const useLogin = ({
    router, setMessage, t, session, status
}: any) => {

    const { from } = router.query;

    // 로그인 에러 처리
    useEffect(() => {
        if (router.query.error?.includes("exists")) {
            toast(
                router.locale == "ko"
                    ? `이미 ${(router.query.error as string)?.split(" ")[8]
                    }로 가입된 계정입니다`
                    : `Account has already signed up by ${(router.query.error as string)?.split(" ")[8]
                    }`,
                {
                    hideProgressBar: true,
                    autoClose: 10000,
                    type: "error",
                    position: "top-center",
                }
            );
        }
        if (router.query.error === "CredentialsSignin") {
            setMessage(t("loginError"));
        }
        if (router.query.error === "gosignup") {
            router.push("/signup?social=true");
        }
    }, [router]);


    //로그인 성공 후 routing 처리
    const customSession = session ? (session.user as CustomSession) : null;
    if (status === "authenticated" && customSession && !customSession?.phone) {
        if (customSession?.platformType === "credentials") {
            router.push("/signup");
        } else {
            router.push("/signup?social=true");
        }
    } else if (status === "authenticated") {
        if (from === 'alarm') {
            router.push("/alarm?from=login");
        }
        else if (from === 'alarmsetting') {
            router.push("/service/alarm?from=login");
        }
        else {
            router.push("/");
        }
    }


}

// 일반 로그인
export const handleLogin = async ({ router, email, password, setMessage, from }: { router: NextRouter; email: string; password: string, setMessage: (vale: string) => void, from?: string }) => {
    // event.preventDefault();
    if (email && password) {

        let callbackUrl = "/";
        if (from === 'alarm') {
            callbackUrl = "/alarm?from=login";
        }
        else if (from === 'alarmsetting') {
            callbackUrl = "/profile/alarm?from=login";
        }

        const result = await signIn("credentials", {
            callbackUrl: callbackUrl,
            email,
            password,
        });

        if (result == undefined || result == null) {
            return;
        }

        if (!result?.error) {
            setMessage("");
            router.push("/");
        } else {
            toast.error(
                router.locale === "ko"
                    ? "로그인 정보를 확인해주세요"
                    : "Invalid email or password"
            );
            setMessage(
                router.locale === "ko"
                    ? "로그인 정보를 확인해주세요"
                    : "Invalid email or password"
            );
        }
    }
};

//소셜 로그인
export const callbackHandler = ({ provider, router, from }: { provider: Provider; router: NextRouter, from?: string }) => {
    // if (from === 'alarm') {
    //     signIn(provider.id, { callbackUrl: `/${router.locale}/alarm` });
    // }
    // else 

    if (localStorage.getItem("clickedPortfolio") == "portfolio") {
        localStorage.removeItem("clickedPortfolio");
        signIn(provider.id, { callbackUrl: `/${router.locale}/portfolio` });
    } else {
        signIn(provider.id);
    }
};

export const sendPasswordChange = async ({
    userEmail, setMessage, router, closeModal, setEmailValid, authResponse
}: any) => {
    if (userEmail.current) {
        const email = userEmail.current?.value as string;
        if (!email.includes("@") || email.length > 30 || email.length < 5) {
            setMessage(
                router.locale === "ko"
                    ? "이메일 형식을 확인해주세요"
                    : "Check your email format"
            );
            return;
        }

        try {
            const response = await fetch("/api/auth/password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: email }),
            });

            authResponse = await response.json();

            if (
                response.status == 500 ||
                authResponse.message === "user doesn't exists"
            ) {
                setMessage(
                    router.locale === "ko"
                        ? "가입된 이메일 주소가 아닙니다."
                        : "This email address is not registered."
                );
                setEmailValid(false);

                return;
            }
            closeModal();
            toast(
                router.locale == "ko"
                    ? `메일을 보냈어요. (최대 5분 소요)`
                    : `Sucessfully sent password reset email`,
                {
                    hideProgressBar: true,
                    autoClose: 10000,
                    type: "success",
                    position: "top-center",
                }
            );
        } catch (e) {
            console.log(e);
        }
    }
};


export default useLogin;