import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";

function LoginSubButtons({ setShowModal, t }: any) {
  const router = useRouter();
  return (
    <>
      <div
        className={`${TYPO.TYPO7_MD} text-gray50 h-6 justify-start items-start gap-6 inline-flex`}
      >
        <p
          className={`${TYPO.TYPO7_MD} text-gray50 cursor-pointer`}
          onClick={() => setShowModal(true)}
        >
          {t("findPassword")}
        </p>
        <p
          className={`${TYPO.TYPO7_MD} text-gray50 cursor-pointer`}
          onClick={() => router.push("/signup")}
        >
          {t("signUp")}
        </p>
      </div>
      <div>
        <p
          onClick={() => {
            window.open("https://open.kakao.com/o/sU32v7ff", "_blank");
          }}
          className={`${TYPO.TYPO7_MD} text-gray50 cursor-pointer`}
        >
          {t("loginIssue")}
        </p>
      </div>
    </>
  );
}
export default LoginSubButtons;
